(function ($) {
  Drupal.behaviors.languageLinks = {
    attach: function (context) {
      var $languageSection = $('.js-gnav-language-section', context);

      this.setLocaleCookie();
      /* Commenting this out until we have lower environments set up
      for AV CA FR site. */
      // this.init($languageSection);
    },
    init: function ($languageSection) {
      var srcUrl = window.top.document.location.href;
      var enUrl = '';
      var frUrl = '';
      var envLower = {
        dev: {
          enUrl: 'https://e.aveda.na.ca.dev.ncsa.elcdev.net',
          frUrl: 'https://devfr.aveda.ca'
        },
        stage: {
          enUrl: 'https://e.aveda.na.ca.stage.ncsa.elcdev.net',
          frUrl: 'https://stagefr.aveda.ca'
        }
      };
      var isProdUrl = srcUrl.match(/www\.aveda|\/fr\.aveda/gu);

      if (isProdUrl === undefined || isProdUrl === null) {
        for (const envPrefix in envLower) {
          if (srcUrl.indexOf(`.${envPrefix}.`) !== -1 || srcUrl.indexOf(`${envPrefix}fr.`) !== -1) {
            enUrl = envLower[envPrefix].enUrl;
            frUrl = envLower[envPrefix].frUrl;
          }
        }
        if (enUrl.length > 0) {
          $('a[href=http\\:\\/\\/www\\.aveda\\.ca\\/]', $languageSection).attr('href', enUrl);
        }
        if (frUrl.length > 0) {
          $('a[href=http\\:\\/\\/fr\\.aveda\\.ca\\/]', $languageSection).attr('href', frUrl);
        }
      };
    },
    setLocaleCookie: function () {
      // Fix LOCALE cookie value if we're in CA FR site.
      if (window.top.document.location.hostname.match(/fr/gu) && $('html').attr('lang') === 'fr') {
        $.cookie('LOCALE', 'fr_CA', {
          path: '/'
        });
      }
    }
  };
})(jQuery);
